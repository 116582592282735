import { Link } from "gatsby"
import React from "react"
import * as styles from "./mobilecontainer.module.scss"



var links = null;

const storeImages = {
    iosLinkImage: require('../images/iosBadge.svg').default,
    androidLinkImage: require('../images/androidBadge.svg').default,
  
  }

export default function MobileContainer(props) {
    
     switch (props.apptype) {
            case "uc": links = {
                ios: "https://example.com/1asdasd",
                and: "https://example.com",
                icon: require('../images/CarChecklistLogo_UsedCar.png').default,
            }
            break;
            case "other": links = {
                ios: "https://example.com/asdasd",
                and: "https://example.com/asdassad",
                icon: require('../images/CarChecklistLogo_NewCar.png').default,
            }
            break;
        }
        
        
return (
<div className={styles.mobilecontainer}>
    <div className={styles.text}> <p>{props.text}</p> </div>
    <div className={styles.appicon}>
    <img alt="appIcon" src={links.icon} height="60" width="60"></img>
    </div>
    <div className={styles.ioslink}>
        <a href={links.ios}>
        <img alt="IosLink" src={storeImages.iosLinkImage} width="150"></img>
        </a>
    </div>
    <div className={styles.androidlink}>
        <a href={links.and}>
        <img alt="AndroidLink" src={storeImages.androidLinkImage}  width="150"></img>
        </a>
    </div>
</div>
)
}

