import React from "react"
import { Link } from "gatsby"
import * as headerStyles from "../components/header.module.scss"

const Header = () => {
  return (
    <header className={headerStyles.header}>
      <div className={headerStyles.globalnav}>
        <ul className="nav-center">
          <li className={headerStyles.navleft}>
            <a href="/">
              <img
                alt="LogoHeader"
                src={require("../images/WebsiteLogo_LogoDark.png").default}
                height="25"
              ></img>
            </a>
          </li>
          <li class={headerStyles.navlink}>
            <Link to="/" activeClassName={headerStyles.activeMenuItem}>
              Home
            </Link>
          </li>
          <li class={headerStyles.navlink}>
            <Link to="/apps/" activeClassName={headerStyles.activeMenuItem}>
              Apps
            </Link>
          </li>
        </ul>
      </div>
    </header>
  )
}

export default Header

/*   <div> 
     <ul className={headerStyles.navcenter}>
      <li className={headerStyles.logo + headerStyles.navlink}>
         <img alt="LogoHeader" src={require("../images/WebsiteLogo_LogoDark.png").default}  height="35" ></img>
      </li>
      <li className={headerStyles.navlink}>
         <Link to="/" activeClassName={headerStyles.activeMenuItem}>
         Home
         </Link>
      </li>
      <li className={headerStyles.navlink}>
         <Link to="/apps/" activeClassName={headerStyles.activeMenuItem}>
         Apps
         </Link>
      </li>
      <li className={headerStyles.navlink}>
         <Link to="/info/" activeClassName={headerStyles.activeMenuItem}>
         Info
         </Link>
      </li>
   </ul>
</div>*/
