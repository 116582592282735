import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import MobileContainer from "../components/mobilecontainer"

const Index = ({data}) => {
  const { title, description } = data.site.siteMetadata
  return (
  <Layout>
    <div className="home-container">
    <center><div className="home-banner2">
    <img alt="LogoHeader" src={require("../images/WebsiteLogo_LogoDark.png").default} height="80" ></img>
    <div><h1>{title}</h1></div>
    <div>{description}</div>
    </div>
    </center>    
    <div className="home-banner1">
    <MobileContainer appIconImage="appIcon" iosImage="iosLinkImage" androidImage="androidLinkImage" text="Used Car" link="ucios" apptype="uc"/>
    <MobileContainer appIconImage="appIcon" iosImage="iosLinkImage" androidImage="androidLinkImage" text="Another app"link="ucAndroid" apptype="other"/>
    <MobileContainer appIconImage="appIcon" iosImage="iosLinkImage" androidImage="androidLinkImage" text="Another app"link="ucAndroid" apptype="other"/>
    <MobileContainer appIconImage="appIcon" iosImage="iosLinkImage" androidImage="androidLinkImage" text="Another app"link="ucAndroid" apptype="other"/>
    <MobileContainer appIconImage="appIcon" iosImage="iosLinkImage" androidImage="androidLinkImage" text="Another app"link="ucAndroid" apptype="other"/>
    
    
    </div>
    </div>
  
    
  </Layout>


  )
}

export const pageQuery = graphql`
  query MetadataQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    image1: file(base: { eq: "header-image.jpeg" }) {
      publicURL
    }
  }
`

export default Index